<template>
  <div class="fill-height">
    <v-container class="pb-15 mb-15" v-if="!error">
      <h1 class="font-weight-light main-h2 mt-5 mb-5">{{ $t("Faq") }}</h1>

      <v-skeleton-loader :loading="is_loading" type="article">
        <div v-html="content" class="subtitle-1"></div>
      </v-skeleton-loader>
    </v-container>
    <error-page :error="error" v-if="error" home="/"></error-page>
  </div>
</template>

<i18n>
    {
    "en": {
    "Faq": "Frequently asked questions",
    "PageDescription": "Do you have thoughts on how Babiry works? Here you will find most of the answers."
    },
    "sv": {
    "Faq": "Vanliga frågor och svar",
    "PageDescription": "Har du funderingar på hur Barndagboken fungerar? Här hittar du de flesta svaren."
    }
    }
</i18n>

<script>
import { blobRequest } from "../../_helpers";

export default {
  name: "faq",
  components: {},
  metaInfo() {
    return {
      title: this.$t("Faq"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Faq") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({
    is_loading: false,
    content: "",
    error: null,
  }),
  created: function () {
    var self = this;
    self.is_loading = true;
    self.content = "";

    blobRequest
      .getText("/page/" + process.env.VUE_APP_LANG + "/Faq.html")
      .then(function (response) {
        self.content = response;
        self.is_loading = false;
      })
      .catch(function (error) {
        self.error = error;
        self.is_loading = false;
      });
  },
};
</script>
<template>
  <div class="fill-height">
    <v-container class="pb-15 mb-15">
      <h1 class="font-weight-light main-h2 mt-5 mb-5">{{ $t("Diaries") }}</h1>
      <p>{{ $t("PageDescription") }}</p>

      <v-skeleton-loader :loading="is_loading" type="article">
        <div class="mt-10">
          <div v-if="!error">
            <v-row>
              <v-col
                xs="12"
                md="4"
                cols="12"
                v-for="item in items"
                :key="item.id"
              >
                <diary-item :diary="item"></diary-item>
              </v-col>
            </v-row>
          </div>
          <error-box :error="error" v-if="error"></error-box>
        </div>
      </v-skeleton-loader>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "Diaries": "Diaries",
    "PageDescription": "Here we see a random selection of diaries on Babiry."
    },
    "sv": {
    "Diaries": "Dagböcker",
    "PageDescription": "Här ser vi ett slumpvis urval av dagböcker som finns på Barndagboken. Gör gärna ett besök."
    }
    }
</i18n>

<script>
import { diaryService } from "@/_services";
import DiaryItem from "@/components/global/DiaryItem.vue";

export default {
  name: "diaries_list",
  components: { "diary-item": DiaryItem },
  metaInfo() {
    return {
      title: this.$t("Diaries"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Diaries") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({
    is_loading: false,
    items: [],
    error: null,
  }),
  created: function () {
    var self = this;
    self.is_loading = true;

    diaryService
      .search(1, 30)
      .then(function (response) {
        self.items = response.data.data;
        self.is_loading = false;
      })
      .catch(function (error) {
        self.error = error;
        self.is_loading = false;
      });
  },
  methods: {},
};
</script>


<style scoped>
.diary-title {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
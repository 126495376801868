<template>
  <v-container class="confirm-page pb-12">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-alert text v-if="error" class="mt-10" color="error">
      <h3 class="headline">{{ $t("ConfirmFailed") }}</h3>
      <div class="pt-3">{{ $t("ConfirmFailedDesc") }}</div>

      <v-divider class="my-4"></v-divider>

      <v-row align="center" no-gutters>
        <v-col class="grow">
          {{ $t("ErrorMessage") }}:
          <u>{{ error }}</u>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink">
          <v-btn color="info" to="/contact" outlined class="text-none">{{ $t("ContactSupport") }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "ConfirmFailed": "Confirmation failed",
    "ConfirmFailedDesc": "We are sorry but something went wrong! Please try again later or contact support for more information.",
    "ErrorMessage": "Error",
    "ContactSupport": "Contact support"
    },
    "sv": {
    "ConfirmFailed": "Verifieringen misslyckades",
    "ConfirmFailedDesc": "Beklagar men något gick fel när verifieringen gjordes. Vänligen prova igen senare eller kontakta supporten för mer information.",
    "ErrorMessage": "Fel",
    "ContactSupport": "Kontakta supporten"
    }
    }
</i18n>

<script>
import { accountService } from "../../_services";
import { responseHelpers } from "@/_helpers";

export default {
  name: "confirm",
  metaInfo() {
    return {
      title: this.$t("Confirm.Title"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Confirm.Title") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title"
        }
      ]
    };
  },
  data: () => ({
    overlay: true,
    error: null
  }),
  created: function() {
    this.send();
  },
  methods: {
    send() {
      var self = this;
      var key = this.$route.params.key;
      var user = this.$route.params.user;

      if (key && user) {
        accountService
          .confirmEmail(user, key)
          .then(function() {
            self.$router.push("/");
          })
          .catch(function(error) {
            self.error = responseHelpers.errorTextFromResponse(error);
            self.overlay = false;
          });
      } else {
        self.$router.push("/");
      }
    }
  }
};
</script>
<template>
  <div class="fill-height">
    <v-container class="terms-page pb-15 mb-15" v-if="!error">
      <h1 class="font-weight-light main-h2 mt-5 mb-5">{{ $t("Terms") }}</h1>
      <v-skeleton-loader :loading="is_loading" type="article">
        <div v-html="content" class="subtitle-1"></div>
      </v-skeleton-loader>
    </v-container>
    <error-page :error="error" v-if="error" home="/"></error-page>
  </div>
</template>
<i18n>
    {
    "en": {
    "Terms": "Terms",
    "PageDescription": "Read Babiry's terms and conditions"
    },
    "sv": {
    "Terms": "Användarvillkor",
    "PageDescription": "Läs Barndagbokens användarvillkor"
    }
    }
</i18n>

<script>
import { blobRequest } from "../../_helpers";

export default {
  name: "privacy",
  components: {},
  metaInfo() {
    return {
      title: this.$t("Terms"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Terms") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({
    is_loading: false,
    content: "",
    error: null,
  }),
  created: function () {
    this.loadPage();
  },
  methods: {
    loadPage() {
      var self = this;
      self.is_loading = true;
      self.content = "";

      blobRequest
        .getText("/page/" + process.env.VUE_APP_LANG + "/terms.html")
        .then(function (response) {
          self.content = response;
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
  },
};
</script>
<template>
  <div class="fill-height">
    <v-container class="pb-15 mb-15">
      <h1 class="font-weight-light main-h2 mt-5 mb-5">{{ $t("Contact") }}</h1>

      <v-row class="pa-0">
        <v-col md="6" xs="12" cols="12" class="pt-0">
          <h2 class="title">{{ $t("QuestionsOrConcerns") }}</h2>
          <p v-html="$t('QuestionsOrConcernsText')"></p>
          <h2 class="title">{{ $t("SocialMedia") }}</h2>

          <ul class="pt-2 pb-3">
            <li>
              <a :href="facebook_url" target="_blank">Facebook</a>
            </li>
            <li>
              <a :href="instagram_url" target="_blank">Instagram</a>
            </li>
            <li>
              <a :href="twitter_url" target="_blank">Twitter</a>
            </li>
          </ul>

          <h2 class="title">{{ $t("Advertisement") }}</h2>
          <p v-html="$t('AdvertisementText')"></p>
          <h2 class="title">{{ $t("Collaborations") }}</h2>
          <p v-html="$t('CollaborationsText')"></p>
        </v-col>
        <v-col md="5" xs="12" cols="12" offset-md="1" class="pt-0">
          <v-form v-model="form" ref="form">
            <v-card flat>
              <v-text-field
                v-model="mail.name"
                :rules="[(v) => !!v || $t('NameRequired')]"
                :loading="is_sending"
                :label="$t('Name')"
                outlined
                class="mb-3 mt-3"
                required
              ></v-text-field>
              <v-text-field
                v-model="mail.email"
                :rules="[
                  (v) => !!v || $t('Validation.EmailRequired'),
                  (v) => /.+@.+\..+/.test(v) || $t('Validation.EmailNotValid'),
                ]"
                :loading="is_sending"
                :label="$t('Email')"
                outlined
                class="mb-3"
                required
              ></v-text-field>
              <v-text-field
                v-model="mail.subject"
                :rules="[(v) => !!v || $t('SubjectRequired')]"
                :loading="is_sending"
                outlined
                :label="$t('Subject')"
                class="mb-3"
                required
              ></v-text-field>
              <v-textarea
                :label="$t('Message')"
                v-model="mail.message"
                :loading="is_sending"
                outlined
                required
                :rules="[(v) => !!v || $t('MessageRequired')]"
              ></v-textarea>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="is_sending"
                  color="primary"
                  @click="sendMail"
                  class="text-none"
                  >{{ $t("Button.Send") }}</v-btn
                >
              </v-card-actions>

              <v-alert
                text
                prominent
                type="error"
                v-if="error"
                icon="$vuetify.icons.cloudAlert"
              >
                <p v-html="$t('MailNotSent')"></p>
                <p class="caption">{{ $t("ErrorMessage") }}: {{ error }}</p>
              </v-alert>
              <v-alert
                text
                prominent
                type="success"
                v-if="is_success"
                icon="$vuetify.icons.emailOutline"
              >
                <p v-html="$t('MailSent')"></p>
              </v-alert>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "Subject": "Subject",
    "Email": "Email",
    "Message": "Message",
    "SuccessSendMail": "Message sent!",
    "Contact": "Contact us",
    "SendMail": "Send us a message",
    "SubjectRequired": "Subject is required!",
    "MessageRequired": "Message is required!",
    "Name": "Name",
    "NameRequired": "Name missing!",
    "Hi": "Hi",
    "MailNotSent": "We are sorry but the message could not be sent. Please try again or contact us on mail, <a href='mailto:info@babiry.com'>info@babiry.com</a>",
    "ErrorMessage": "Error message",
    "MailSent": "Thank you for your message. We will respond as soon as possible.",
    "QuestionsOrConcerns": "Questions or concerns?",
    "QuestionsOrConcernsText": "You can get in touch with us through the form to the right. We would ask that you first read our FAQ , where we have posted answers to the most commonly asked questions we receive.",
    "SocialMedia": "Babiry on social media",
    "Advertisement": "Advertisement",
    "AdvertisementText": "Want you or your Company to appear on Babiry? Feel free to contact us using the form on the right.",
    "Collaborations": "Collaborations",
    "CollaborationsText": "Interested in a collaborating with Babiry? Please contact us and describe what you want out of such a collaboration and what Babiry will get out of it. A collaboration could be, for example, if you wanted to create a contest for Babiry’s users and raffle off prizes.",
    "PageDescription": "If you want to contact Babiry, you can do so via the form or via social media."
    },
    "sv": {
    "Subject": "Ämne",
    "Email": "Email",
    "Message": "Meddelande",
    "SendMail": "Skicka oss ett meddelande",
    "SuccessSendMail": "Meddelande skickat!",
    "Contact": "Kontakta oss",
    "SubjectRequired": "Ämne saknas!",
    "MessageRequired": "Meddelande saknas!",
    "Name": "Namn",
    "NameRequired": "Namn saknas!",
    "Hi": "Hej",
    "MailNotSent": "Tyvärr kunde inte meddelandet skickas. Vänligen prova igen eller kontakta oss på mailadressen, <a href='mailto:info@barndagboken.se'>info@barndagboken.se</a>",
    "ErrorMessage": "Felmeddelande",
    "MailSent": "Tack för ditt mail! Vi besvarar dig så snart som möjligt.",
    "QuestionsOrConcerns": "Frågor eller funderingar?",
    "QuestionsOrConcernsText": "Har du några frågor som rör Barndagboken kan du genom formuläret till höger komma i kontakt med oss. Vi ber Er först att läsa vår Faq där vi har publicerat svar på de vanligaste frågorna vi har fått.",
    "SocialMedia": "Barndagboken i sociala medier",
    "Advertisement": "Annonsering",
    "AdvertisementText": "Vill du eller ditt företag synas på Barndagboken? Tveka inte att kontakta oss genom formuläret till höger.",
    "Collaborations": "Samarbeten",
    "CollaborationsText": "Intresserad av ett samarbete med Barndagboken? Kontakta oss gärna och beskriv vad ni vill få ut av ett sådant samarbete och vad Barndagboken kommer att få ut av det hela. Ett samarbete kan tex. vara där du vill skapa en tävling bland Barndagbokens användare och lotta ut något pris/priser.",
    "PageDescription": "Vill du kontakta Barndagboken kan du göra det via formuläret eller via sociala medier."
    }
    }
</i18n>

<script>
import { globalService } from "../../_services";
import { mapState } from "vuex";

export default {
  name: "contact",
  metaInfo() {
    return {
      title: this.$t("Contact"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Contact") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    is_sending: false,
    form: true,

    mail: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    error: null,
    is_success: false,

    facebook_url: process.env.VUE_APP_FACEBOOK_PAGE_URL,
    instagram_url: process.env.VUE_APP_INSTAGRAM_PAGE_URL,
    twitter_url: process.env.VUE_APP_TWITTER_PAGE_URL,
  }),
  created: function () {
    if (this.user) {
      this.mail.name = this.user.name;
      this.mail.email = this.user.email;
    }
  },
  methods: {
    sendMail() {
      var self = this;
      self.error = null;
      self.is_success = false;

      if (self.$refs.form.validate()) {
        self.is_sending = true;

        globalService
          .contactSupport(self.mail)
          .then(function () {
            self.is_sending = false;
            self.is_success = true;

            self.mail.subject = "";
            self.mail.message = "";

            if (!self.user) {
              self.mail.name = "";
              self.mail.email = "";
            }
            self.$refs.form.resetValidation();
          })
          .catch(function (error) {
            self.is_sending = false;
            self.error = error;
          });
      }
    },
  },
};
</script>